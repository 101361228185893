<template>
  <div>
    <div class="wapper">
      <div class="siteWrap">
        <div class="line_info clearfix" style="position: relative">
          <div class="site">
              <span class="from" id="from">{{ lineInfoData.fromStation }} → </span>
              <span class="to" id="to">{{ lineInfoData.toStation }}</span>
          </div>
          <div class="tit">
              <span class="time_s">首班: {{time_s}}</span>
              <span class="time_e">末班: {{time_e}}</span>
          </div>
        </div>
      </div>
      <div class="line_list">
        <!--
        <div class="title">首站历史发车间隔</div>
        -->
        <div class="title">上午</div>
        <ul class="clearfix">
          <li class="clearfix" v-for="item in lineInfoData.am"
              :key="item.id">
              <span class="timerange">{{item}}</span>
              <!--
              <span class="timecount">{{times}}分钟/班</span>
              -->
          </li>
        </ul>
        <div class="title" style="margin-top: 12px;">下午</div>
        <ul>
          <li class="clearfix" v-for="item in lineInfoData.pm"
              :key="item.id">
              <span class="timerange">{{item}}</span>
              <!--
              <span class="timecount">{{times}}分钟/班</span>
              -->
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { connectBusHub, getBusLineDeparture } from '../../signalR/busHub'

export default {
  data () {
    return {
      time_s: '',
      time_e: '',
      times: 0,
      lineInfoData: {}
    }
  },
  created () {
    const query = this.$route.query
    this.busLineId = Number(query.busLineId)
    this.direction = Number(query.direction)
  },
  mounted () {
    this.getBusLineDeparture(this.busLineId, this.direction)
  },
  methods: {
    getBusLineDeparture (busLineId, direction) {
      connectBusHub().then((busHub) => {
        getBusLineDeparture(busHub, busLineId, direction).then((res) => {
          console.log(res)
          this.time_s = res.am[0]
          this.time_e = res.pm[res.pm.length-1]
          this.lineInfoData = res
          this.times = this.dateTime(res.am[1], res.am[0])
          console.log('获取发车时间表成功')
        }).catch(err => {
          console.log(err)
          console.log('获取发车时间表失败')
        })
      }).catch(err => {
        console.log(err)
      })
    },
    // 废弃
    dateTime: function(end, start) {
        const regexp = /^0+/g;
        end = end.replace(regexp, '');
        start = start.replace(regexp, '');
        let endArr = end.split(':');
        let startArr = start.split(':');
        return Number(endArr[0])*60 + Number(endArr[1]) - (Number(startArr[0])*60 + Number(startArr[1])) ;
    }
  }
}
</script>


<style lang="scss" scoped>
.siteWrap {
    background: #fff;
    border-radius: 8px;
    color: #292a2c;
    font-size: 0.32rem;
    width: 100%;
    margin-bottom: 0.2rem;
    padding: 0.3rem;
    text-align: left;
    .tit {
        font-size: 0.24rem;
        margin-top: 10px;
        color: #9a9da0;
        span {
            float: left;
            margin-right: 0.2rem;
        }
        .time_table {
            color: #36a3f9;
            cursor: pointer;
        }
    }
}
.line_list {
    margin-top: 0.3rem;
    background: #fff;
    border-radius: 8px;
    color: #292a2c;
    font-size: 0.32rem;
    width: 100%;
    margin-bottom: 0.2rem;
    padding: 0.3rem;
    text-align: left;
    .title{
        color: #36a3f9;
        margin-bottom: 0.2rem;
    }
    ul{
        li{
            color: #9a9da0;
            padding: 0.26rem 0;
            border-bottom: 1px solid #e4e7ed;
            .timecount{
                float: right;
                color: #292a2c;
            }
        }
    }
}


</style>
